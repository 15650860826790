import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('@/view/pages/Dashboard.vue')
        },
        {
          path: '/stores',
          // name: 'stores',
          component: () => import('@/view/pages/stores/Store.vue'),
          children: [
            {
              path: '/',
              name: 'list-store',
              component: () => import('@/view/pages/stores/ListStore.vue'),
              meta: {
                title: 'Danh sách cửa hàng - DDV'
              }
            },
            {
              path: 'add-store',
              name: 'add-store',
              component: () => import('@/view/pages/stores/AddStore.vue'),
              meta: {
                title: 'Thêm mới cửa hàng - DDV'
              }
            },
            {
              path: 'edit-store',
              name: 'edit-store',
              component: () => import('@/view/pages/stores/EditStore.vue'),
              meta: {
                title: 'Cập nhật cửa hàng - DDV'
              }
            }
          ]
        },
        {
          path: '/order-source',
          // name: 'order-source',
          component: () => import('@/view/pages/order-source/OrderSource.vue'),
          children: [
            {
              path: '/',
              name: 'list-order-source',
              component: () =>
                import('@/view/pages/order-source/ListOrderSource.vue'),
              meta: {
                title: 'Danh sách nguồn đơn hàng - DDV'
              }
            }
          ]
        },
        {
          path: '/customers',
          // name: 'customers',
          component: () => import('@/view/pages/customers/Customer.vue'),
          children: [
            {
              path: '/',
              name: 'list-customer',
              component: () =>
                import('@/view/pages/customers/ListCustomer.vue'),
              meta: {
                title: 'Danh sách khách hàng - DDV'
              }
            },
            {
              path: 'add-customer',
              name: 'add-customer',
              component: () => import('@/view/pages/customers/AddCustomer.vue'),
              meta: {
                title: 'Thêm mới khách hàng - DDV'
              }
            },
            {
              path: 'update-customer',
              name: 'update-customer',
              component: () =>
                import('@/view/pages/customers/UpdateCustomer.vue'),
              meta: {
                title: 'Cập nhật khách hàng - DDV'
              }
            }
          ]
        },
        {
          path: '/categories',
          // name: 'categories',
          component: () => import('@/view/pages/categories/Categories.vue'),
          children: [
            {
              path: '/',
              name: 'list-category',
              component: () =>
                import('@/view/pages/categories/ListCategory.vue'),
              meta: {
                title: 'Danh mục nội bộ - DDV'
              }
            },
            {
              path: 'add-category',
              name: 'add-category',
              component: () =>
                import('@/view/pages/categories/AddCategory.vue'),
              meta: {
                title: 'Thêm mới danh mục nội bộ  - DDV'
              }
            },
            {
              path: 'update-category',
              name: 'update-category',
              component: () =>
                import('@/view/pages/categories/UpdateCategory.vue'),
              meta: {
                title: 'Cập nhật danh mục nội bộ  - DDV'
              }
            }
          ]
        },
        {
          path: '/suppliers',
          // name: 'suppliers',
          component: () => import('@/view/pages/suppliers/Supplier.vue'),
          children: [
            {
              path: '/',
              name: 'list-suppliers',
              component: () =>
                import('@/view/pages/suppliers/ListSupplier.vue'),
              meta: {
                title: 'Danh sách nhà cung cấp - DDV'
              }
            }
          ]
        },
        {
          path: '/products',
          component: () => import('@/view/pages/products/Product.vue'),
          children: [
            {
              path: '/',
              name: 'list-products',
              component: () => import('@/view/pages/products/ListProducts.vue'),
              meta: {
                title: 'Danh sách sản phẩm - DDV'
              }
            },
            {
              path: 'add-product',
              name: 'addProduct',
              component: () => import('@/view/pages/products/AddProduct.vue'),
              meta: {
                title: 'Thêm mới sản phẩm - DDV'
              }
            },
            {
              path: 'import-product',
              name: 'import-product',
              component: () =>
                import('@/view/pages/products/ImportProduct.vue'),
              meta: {
                title: 'Nhập sản phẩm - DDV'
              }
            },
            {
              path: 'import-product-combo',
              name: 'import-product-combo',
              component: () =>
                import('@/view/pages/products/ImportProductCombo.vue'),
              meta: {
                title: 'Nhập sản phẩm combo - DDV'
              }
            },
            {
              path: 'update-product',
              name: 'update-product',
              component: () =>
                import('@/view/pages/products/UpdateProduct.vue'),
              meta: {
                title: 'Cập nhật sản phẩm - DDV'
              }
            },
            {
              path: 'inventory-detail-imei',
              name: 'inventory-detail-imei',
              component: () =>
                import('@/view/pages/products/InventoryDetail4IMEI.vue'),
              meta: {
                title: 'Chi tiết tồn kho sản phẩm IMEI - DDV'
              }
            },
            {
              path: 'inventory-detail',
              name: 'inventory-detail',
              component: () =>
                import('@/view/pages/products/InventoryDetail.vue'),
              meta: {
                title: 'Chi tiết tồn kho sản phẩm - DDV'
              }
            },
            {
              path: 'update-inventory-detail',
              name: 'update-inventory-detail',
              component: () =>
                import('@/view/pages/products/UpdateID4Imei.vue'),
              meta: {
                title: 'Chi tiết IMEI - DDV'
              }
            },
            {
              path: 'print-barcode',
              name: 'print-barcode',
              component: () => import('@/view/pages/products/PrintBarcode.vue'),
              meta: {
                title: 'In mã vạch sản phẩm - DDV'
              },
              props: true
            },
            {
              path: 'print-product',
              name: 'print-product',
              component: () => import('@/view/pages/products/ProductPrint.vue'),
              meta: {
                title: 'In sản phẩm - DDV'
              }
            }
          ]
        },
        {
          path: '/permissions',
          // name: 'permissions',
          component: () => import('@/view/pages/permissions/Permission.vue'),
          children: [
            {
              path: '/',
              name: 'list-permission',
              component: () =>
                import('@/view/pages/permissions/ListPermission.vue'),
              meta: {
                title: 'Danh sách phân quyền - DDV'
              }
            },
            {
              path: 'add-permission',
              name: 'add-permission',
              component: () =>
                import('@/view/pages/permissions/AddPermission.vue'),
              meta: {
                title: 'Thêm mới phân quyền - DDV'
              }
            },
            {
              path: 'update-permission',
              name: 'update-permission',
              component: () =>
                import('@/view/pages/permissions/UpdatePermission.vue'),
              meta: {
                title: 'Cập nhật phân quyền - DDV'
              }
            }
          ]
        },
        {
          path: '/users',
          // name: 'users',
          component: () => import('@/view/pages/users/User.vue'),
          children: [
            {
              path: '/',
              name: 'list-user',
              component: () => import('@/view/pages/users/ListUser.vue'),
              meta: {
                title: 'Danh sách tài khoản - DDV'
              }
            },
            {
              path: 'add-user',
              name: 'add-user',
              component: () => import('@/view/pages/users/AddUser.vue'),
              meta: {
                title: 'Thêm mới tài khoản - DDV'
              }
            },
            {
              path: 'update-user',
              name: 'update-user',
              component: () => import('@/view/pages/users/UpdateUser.vue'),
              meta: {
                title: 'Cập nhật tài khoản - DDV'
              }
            }
          ]
        },
        {
          path: '/employees',
          // name: 'employees',
          component: () => import('@/view/pages/employees/Employee.vue'),
          children: [
            {
              path: '/',
              name: 'list-employee',
              component: () =>
                import('@/view/pages/employees/ListEmployee.vue'),
              meta: {
                title: 'Danh sách nhân sự - DDV'
              }
            },
            {
              path: 'add-employee',
              name: 'add-employee',
              component: () => import('@/view/pages/employees/AddEmployee.vue'),
              meta: {
                title: 'Thêm mới nhân sự - DDV'
              }
            },
            {
              path: 'update-employee',
              name: 'update-employee',
              component: () =>
                import('@/view/pages/employees/UpdateEmployee.vue'),
              meta: {
                title: 'Cập nhật nhân sự - DDV'
              }
            },
            {
              path: 'import-employee',
              name: 'import-employee',
              component: () =>
                import('@/view/pages/employees/ImportEmployee.vue'),
              meta: {
                title: 'Nhập nhân sự - DDV'
              }
            }
          ]
        },
        {
          path: '/properties',
          // name: 'properties',
          component: () => import('@/view/pages/properties/Property.vue'),
          children: [
            {
              path: '/',
              name: 'list-property',
              component: () =>
                import('@/view/pages/properties/ListProperty.vue'),
              meta: {
                title: 'Danh sách thuộc tính sản phẩm - DDV'
              }
            },
            {
              path: 'add-property',
              name: 'add-property',
              component: () =>
                import('@/view/pages/properties/AddProperty.vue'),
              meta: {
                title: 'Thêm mới thuộc tính sản phẩm - DDV'
              }
            },
            {
              path: 'update-property',
              name: 'update-property',
              component: () =>
                import('@/view/pages/properties/UpdateProperty.vue'),
              meta: {
                title: 'Cập nhật thuộc tính sản phẩm - DDV'
              }
            }
          ]
        },
        {
          path: '/providers',
          // name: 'providers',
          component: () => import('@/view/pages/providers/Provider.vue'),
          children: [
            {
              path: '/',
              name: 'list-provider',
              component: () =>
                import('@/view/pages/providers/ListProvider.vue'),
              meta: {
                title: 'Danh sách nhà cung cấp - DDV'
              }
            },
            {
              path: 'add-provider',
              name: 'add-provider',
              component: () => import('@/view/pages/providers/AddProvider.vue'),
              meta: {
                title: 'Thêm mới nhà cung cấp - DDV'
              }
            },
            {
              path: 'update-provider',
              name: 'update-provider',
              component: () =>
                import('@/view/pages/providers/UpdateProvider.vue'),
              meta: {
                title: 'Cập nhật nhà cung cấp - DDV'
              }
            }
          ]
        },
        {
          path: '/brands',
          // name: 'brands',
          component: () => import('@/view/pages/brands/Brand.vue'),
          children: [
            {
              path: '/',
              name: 'list-brand',
              component: () => import('@/view/pages/brands/ListBrand.vue'),
              meta: {
                title: 'Danh sách thương hiệu - DDV'
              }
            }
          ]
        },
        {
          path: '/stocks',
          // name: 'stocks',
          component: () => import('@/view/pages/stocks/Stock.vue'),
          children: [
            {
              path: '/',
              name: 'list-stock',
              component: () => import('@/view/pages/stocks/ListStock.vue'),
              meta: {
                title: 'Danh sách phiếu nhập kho - DDV'
              }
            },
            {
              path: 'add-stock',
              name: 'add-stock',
              component: () => import('@/view/pages/stocks/AddStock.vue'),
              meta: {
                title: 'Thêm mới phiếu nhập kho - DDV'
              }
            },
            {
              path: 'update-stock',
              name: 'update-stock',
              component: () => import('@/view/pages/stocks/UpdateStock.vue'),
              meta: {
                title: 'Cập nhật phiếu nhập kho - DDV'
              }
            },
            {
              path: 'print-import-stockslip',
              name: 'print-import-stockslip',
              component: () =>
                import('@/view/pages/print-stockslips/PrintImportExport.vue'),
              meta: {
                title: 'In phiếu nhập kho - DDV'
              }
            }
          ]
        },
        {
          path: '/export-stocks',
          // name: 'export-stocks',
          component: () => import('@/view/pages/stocks/ExportStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-export-stock',
              component: () => import('@/view/pages/stocks/ListEStock.vue'),
              meta: {
                title: 'Danh sách phiếu xuất kho - DDV'
              }
            },
            {
              path: 'add-export-stock',
              name: 'add-export-stock',
              component: () => import('@/view/pages/stocks/AddEStock.vue'),
              meta: {
                title: 'Thêm mới phiếu xuất kho - DDV'
              }
            },
            {
              path: 'update-export-stock',
              name: 'update-export-stock',
              component: () => import('@/view/pages/stocks/UpdateEStock.vue'),
              meta: {
                title: 'Cập nhật phiếu xuất kho - DDV'
              }
            },
            {
              path: 'print-export-stockslip',
              name: 'print-export-stockslip',
              component: () =>
                import('@/view/pages/print-stockslips/PrintImportExport.vue'),
              meta: {
                title: 'In phiếu xuất kho - DDV'
              }
            }
          ]
        },
        {
          path: '/transfer-stocks',
          // name: 'transfer-stocks',
          component: () => import('@/view/pages/stocks/TransferStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-transfer-stock',
              component: () => import('@/view/pages/stocks/ListTStock.vue'),
              meta: {
                title: 'Danh sách phiếu chuyển kho - DDV'
              }
            },
            {
              path: 'add-transfer-stock',
              name: 'add-transfer-stock',
              component: () =>
                import('@/view/pages/stocks/AddTransferStock.vue'),
              meta: {
                title: 'Thêm mới phiếu chuyển kho - DDV'
              }
            },
            {
              path: 'update-transfer-stock',
              name: 'update-transfer-stock',
              component: () =>
                import('@/view/pages/stocks/UpdateTransferStock.vue'),
              meta: {
                title: 'Cập nhật phiếu chuyển kho - DDV'
              }
            },
            {
              path: 'print-transfer-stockslip',
              name: 'print-transfer-stockslip',
              component: () =>
                import('@/view/pages/print-stockslips/PrintTransfer.vue'),
              meta: {
                title: 'In phiếu chuyển kho - DDV'
              }
            }
          ]
        },
        {
          path: '/transfer-stock-deleted',
          component: () => import('@/view/pages/stocks/TStockSlipDeleted.vue'),
          children: [
            {
              path: '/',
              name: 'list-transfer-stock-deleted',
              component: () =>
                import('@/view/pages/stocks/ListTStockSlipDeleted.vue'),
              meta: {
                title: 'Danh sách phiếu chuyển kho đã xóa - DDV'
              }
            },
            {
              path: 'detail-transfer-stock-deleted',
              name: 'detail-transfer-stock-deleted',
              component: () =>
                import('@/view/pages/stocks/DetailTStockSlipDeleted.vue'),
              meta: {
                title: 'Thông tin phiếu chuyển kho đã xóa - DDV'
              }
            }
          ]
        },
        {
          path: '/orders',
          // name: 'orders',
          component: () => import('@/view/pages/orders/Order.vue'),
          children: [
            {
              path: '/',
              name: 'list-order',
              component: () => import('@/view/pages/orders/ListOrder.vue'),
              meta: {
                title: 'Danh sách đơn hàng - DDV'
              }
            },
            {
              path: 'edit-order/:id',
              name: 'edit-order',
              component: () => import('@/view/pages/orders/UpsertOrder.vue'),
              meta: {
                title: 'Chỉnh sửa đơn hàng - DDV'
              },
              props: true
            },
            {
              path: 'add-order',
              name: 'add-order',
              component: () => import('@/view/pages/orders/UpsertOrder.vue'),
              meta: {
                title: 'Thêm mới đơn hàng - DDV'
              }
            },
            {
              path: 'update-order',
              name: 'update-order',
              component: () => import('@/view/pages/orders/UpdateOrder.vue'),
              meta: {
                title: 'Cập nhật đơn hàng - DDV'
              }
            },
            {
              path: 'print-order',
              name: 'print-order',
              component: () => import('@/view/pages/orders/PrintOrder.vue'),
              meta: {
                title: 'In đơn hàng - DDV'
              }
            }
          ]
        },
        {
          path: '/pre-order',
          component: () => import('@/view/pages/orders/Order.vue'),
          children: [
            {
              path: '/',
              name: 'list-pre-order',
              component: () => import('@/view/pages/pre-orders/PreOrder.vue'),
              meta: {
                title: 'Danh sách đơn hàng đặt trước - DDV'
              }
            }
          ]
        },
        {
          path: '/pre-order-form',
          component: () =>
            import('@/view/pages/pre-order-forms/PreOrderForm.vue'),
          children: [
            {
              path: '/',
              component: () =>
                import('@/view/pages/pre-order-forms/PreOrderFormList.vue'),
              meta: {
                title: 'Chương trình đơn hàng đặt trước - DDV'
              }
            }
          ]
        },
        {
          path: '/installment-program',
          component: () =>
            import('@/view/pages/installment-programs/InstallmentProgram.vue'),
          children: [
            {
              path: '/',
              name: 'list-installment-program',
              component: () =>
                import(
                  '@/view/pages/installment-programs/InstallmentProgramList.vue'
                ),
              meta: {
                title: 'Cấu hình chương trình trả góp - DDV'
              }
            },
            {
              path: 'add-installment-program',
              name: 'add-installment-program',
              component: () =>
                import(
                  '@/view/pages/installment-programs/UpsertInstallmentProgram.vue'
                ),
              meta: {
                title: 'Tạo chương trình trả góp - DDV'
              }
            },
            {
              path: 'edit-installment-program/:id',
              name: 'edit-installment-program',
              component: () =>
                import(
                  '@/view/pages/installment-programs/UpsertInstallmentProgram.vue'
                ),
              meta: {
                title: 'Cập nhật chương trình trả góp - DDV'
              },
              props: true
            }
          ]
        },
        {
          path: '/product-require-stocks',
          component: () => import('@/view/pages/stocks/RStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-require',
              component: () =>
                import('@/view/pages/stocks/ListProductRequire.vue'),
              meta: {
                title: 'Danh sách sản phẩm XNK - DDV'
              }
            }
          ]
        },
        {
          path: '/product-require-stock-to-nhanh',
          component: () => import('@/view/pages/stocks/TransferToNhanh.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-require-to-nhanh',
              component: () =>
                import('@/view/pages/stocks/ListProductRequireToNhanh.vue'),
              meta: {
                title: 'Danh sách sản phẩm XNK sang hệ thống cũ - DDV'
              }
            }
          ]
        },
        {
          path: '/require-stocks',
          component: () => import('@/view/pages/stocks/RStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-require-stock',
              component: () =>
                import('@/view/pages/stocks/ListRequireStock.vue'),
              meta: {
                title: 'Danh sách phiếu yêu cầu chuyển kho - DDV'
              }
            },
            {
              path: 'approve-transfer-stock',
              name: 'approve-transfer-stock',
              component: () =>
                import('@/view/pages/stocks/ApproveTransferStock.vue'),
              meta: {
                title: 'Duyệt phiếu yêu cầu chuyển kho - DDV'
              }
            },
            {
              path: 'confirm-transfer-stock',
              name: 'confirm-transfer-stock',
              component: () =>
                import('@/view/pages/stocks/ConfirmTransferStock.vue'),
              meta: {
                title: 'Xác nhận phiếu yêu cầu chuyển kho - DDV'
              }
            },
            {
              path: 'print-require-stockslip',
              name: 'print-require-stockslip',
              component: () =>
                import('@/view/pages/print-stockslips/PrintTransfer.vue'),
              meta: {
                title: 'In phiếu chuyển kho - DDV'
              }
            }
          ]
        },
        {
            path: '/old-imeis',
            component: () => import('@/view/pages/old-imeis/FindImei.vue'),
            name: 'list-old-imeis',
        },
        {
          path: '/imeis',
          component: () => import('@/view/pages/imeis/Imei.vue'),
          children: [
            {
              path: '/',
              name: 'list-imeis',
              component: () => import('@/view/pages/imeis/ListImei.vue'),
              meta: {
                title: 'Danh sách IMEI - DDV'
              }
            },
            {
              path: 'update-imei',
              name: 'update-imei',
              component: () =>
                import('@/view/pages/products/UpdateID4Imei.vue'),
              meta: {
                title: 'Cập nhật IMEI - DDV'
              }
            },
            {
              path: 'change-product-imei',
              name: 'change-product-imei',
              component: () => import('@/view/pages/imeis/ChangeProduct.vue'),
              meta: {
                title: 'Đổi sản phẩm IMEI - DDV'
              }
            },
            {
              path: 'import-change-product-imei',
              name: 'import-change-product-imei',
              component: () => import('@/view/pages/imeis/ImportChangeProduct.vue'),
              meta: {
                title: 'Import đổi sản phẩm IMEI - DDV'
              }
            },
            {
              path: 'active-imei',
              name: 'active-imei',
              component: () => import('@/view/pages/imeis/ImportActive.vue'),
              meta: {
                title: 'Kích hoạt IMEI - DDV'
              }
            }
          ]
        },
        {
          path: '/accounting',
          component: () => import('@/view/pages/accountant/Accountant.vue'),
          children: [
            {
              path: 'accounts',
              name: 'list-accountant',
              component: () =>
                import('@/view/pages/accountant/ListAccountant.vue'),
              meta: {
                title: 'Danh sách tài khoản kế toán - DDV'
              }
            },
            {
              path: 'accounts/add',
              name: 'add-accountant',
              component: () =>
                import('@/view/pages/accountant/AddAccountant.vue'),
              meta: {
                title: 'Thêm mới tài khoản kế toán - DDV'
              }
            },
            {
              path: 'accounts/update',
              name: 'update-accountant',
              component: () =>
                import('@/view/pages/accountant/UpdateAccountant.vue'),
              meta: {
                title: 'Cập nhật tài khoản kế toán - DDV'
              }
            },
            // THU CHI TIỀN MẶT, NGÂN HÀNG
            {
              path: 'transactions',
              name: 'accountant-transactions',
              component: () =>
                import('@/view/pages/transaction/Transaction.vue'),
              children: [
                {
                  path: ':mode',
                  name: 'list-transaction',
                  component: () =>
                    import('@/view/pages/transaction/ListTransaction.vue'),
                  props: true,
                  meta: {
                    title: 'Phiếu thu chi - DDV'
                  }
                },
                {
                  path: ':mode/:action/:id',
                  name: 'transaction',
                  component: () =>
                    import('@/view/pages/transaction/AddTransaction.vue'),
                  props: true
                }
              ]
            },
            {
              path: 'debts',
              name: 'accountant-debt',
              component: () => import('@/view/pages/debt/Debt.vue'),
              children: [
                {
                  path: 'items',
                  name: 'list-debt-items',
                  component: () => import('@/view/pages/debt/Items.vue'),
                  meta: {
                    title: 'Công nợ trả góp hóa đơn theo ngày - DDV'
                  }
                },
                {
                  path: 'supplierduedate',
                  name: 'list-debt-supplierduedate',
                  component: () =>
                    import('@/view/pages/debt/ProviderItems.vue'),
                  meta: {
                    title: 'Hạn thanh toán nhà cung cấp - DDV'
                  }
                },
                {
                  path: 'installments',
                  name: 'list-debt-installments',
                  component: () => import('@/view/pages/debt/Installment.vue'),
                  meta: {
                    title: 'Công nợ dịch vụ trả góp - DDV'
                  }
                },
                {
                  path: 'credits',
                  name: 'list-debt-credits',
                  component: () => import('@/view/pages/debt/CreditItems.vue'),
                  meta: {
                    title: 'Công nợ quẹt thẻ hóa đơn theo ngày - DDV'
                  }
                },
                {
                  path: 'providers',
                  name: 'list-debt-providers',
                  component: () => import('@/view/pages/debt/Provider.vue'),
                  meta: {
                    title: 'Công nợ nhà cung cấp  - DDV'
                  }
                },
                {
                  path: 'credit-orders',
                  name: 'list-debt-credit-order',
                  component: () => import('@/view/pages/debt/CreditOrder.vue'),
                  meta: {
                    title: 'Công nợ quẹt thẻ đơn hàng - DDV'
                  }
                },
                {
                  path: 'installment-orders',
                  name: 'list-debt-installment-order',
                  component: () =>
                    import('@/view/pages/debt/InstallmentOrder.vue'),
                  meta: {
                    title: 'Công nợ quẹt thẻ đơn hàng - DDV'
                  }
                },
                {
                  path: 'ship-fee-orders',
                  name: 'list-ship-fee-orders',
                  component: () =>
                    import('@/view/pages/debt/ShipFeeOrder.vue'),
                  meta: {
                    title: 'Công nợ phí ship đơn hàng - DDV'
                  }
                },
                {
                  path: 'purchase-order',
                  name: 'list-debt-purchase-order',
                  component: () =>
                    import('@/view/pages/debt/PurchaseOrder.vue'),
                  meta: {
                    title: 'Công nợ nhập hàng - DDV'
                  }
                }
              ]
            },
            // DỊCH VỤ TRẢ GÓP
            {
              path: 'installments',
              component: () =>
                import('@/view/pages/installment/Installment.vue'),
              children: [
                {
                  path: '/',
                  name: 'list-installment',
                  component: () =>
                    import('@/view/pages/installment/ListInstallment.vue'),
                  meta: {
                    title: 'Danh sách dịch vụ trả góp - DDV'
                  }
                },
                {
                  path: 'add',
                  name: 'add-installment',
                  component: () =>
                    import('@/view/pages/installment/AddInstallment.vue'),
                  meta: {
                    title: 'Thêm mới dịch vụ trả góp - DDV'
                  }
                },
                {
                  path: 'update',
                  name: 'update-installment',
                  component: () =>
                    import('@/view/pages/installment/UpdateInstallment.vue'),
                  meta: {
                    title: 'Cập nhật dịch vụ trả góp - DDV'
                  }
                }
              ]
            },
            {
              path: 'import-excel',
              component: () =>
                import('@/view/pages/import-excel/ImportExcel.vue'),
              children: [
                {
                  path: 'debt/:mode',
                  name: 'import-debt',
                  component: () => import('@/view/pages/import-excel/Debt.vue'),
                  meta: {
                    title: 'Nhập excel công nợ'
                  }
                }
              ]
            }
          ]
        },
        {
          path: '/bills',
          component: () => import('@/view/pages/bills/Bill.vue'),
          children: [
            {
              path: '/',
              name: 'list-bill',
              component: () => import('@/view/pages/bills/ListBill.vue'),
              meta: {
                title: 'Danh sách hóa đơn bán lẻ - DDV'
              }
            },
            {
              path: 'add-bill',
              name: 'add-bill',
              component: () => import('@/view/pages/bills/BillInfo.vue'),
              meta: {
                title: 'Thêm mới hóa đơn bán lẻ - DDV',
              },
            },
            {
              path: 'update-bill',
              name: 'update-bill',
              component: () => import('@/view/pages/bills/BillInfo.vue'),
              meta: {
                title: 'Cập nhật hóa đơn bán lẻ - DDV',
              },
            },
            {
              path: 'print-bill',
              name: 'print-bill',
              component: () => import('@/view/pages/bills/PrintBill.vue'),
              meta: {
                title: 'In hóa đơn - DDV'
              }
            },
            {
              path: 'add-bill-from-order',
              name: 'add-bill-from-order',
              component: () => import('@/view/pages/orders/AddBill.vue'),
              meta: {
                title: 'Thêm mới hóa đơn từ đơn hàng - DDV'
              }
            }
          ]
        },
        {
          path: '/bill-history',
          component: () => import('@/view/pages/bills/history/BillHistory.vue'),
          children: [
            {
              path: '/',
              name: 'list-bill-history',
              component: () =>
                import('@/view/pages/bills/history/ListBillHistory.vue'),
              meta: {
                title: 'Lịch sử hóa đơn - DDV'
              }
            },
            {
              path: 'detail-bill-history',
              name: 'detail-bill-history',
              component: () =>
                import('@/view/pages/bills/history/DetailBillHistory.vue'),
              meta: {
                title: 'Chi tiết lịch sử hóa đơn - DDV'
              }
            }
          ]
        },
        {
          path: '/order-history',
          component: () =>
            import('@/view/pages/orders/history/OrderHistory.vue'),
          children: [
            {
              path: '/',
              name: 'list-order-history',
              component: () =>
                import('@/view/pages/orders/history/ListOrderHistory.vue'),
              meta: {
                title: 'Lịch sử đơn hàng - DDV'
              }
            },
            {
              path: 'detail-order-history',
              name: 'detail-order-history',
              component: () =>
                import('@/view/pages/orders/history/DetailOrderHistory.vue'),
              meta: {
                title: 'Chi tiết lịch sử đơn hàng - DDV'
              }
            }
          ]
        },
        {
          path: '/accessories',
          component: () => import('@/view/pages/accessories/Accessory.vue'),
          children: [
            {
              path: '/',
              name: 'list-accessory',
              component: () =>
                import('@/view/pages/accessories/ListAccessory.vue'),
              meta: {
                title: 'Danh sách bộ sản phẩm tặng kèm - DDV'
              }
            },
            {
              path: 'add-accessory',
              name: 'add-accessory',
              component: () =>
                import('@/view/pages/accessories/AddAccessory.vue'),
              meta: {
                title: 'Thêm mới bộ sản phẩm tặng kèm - DDV'
              }
            },
            {
              path: 'update-accessory',
              name: 'update-accessory',
              component: () =>
                import('@/view/pages/accessories/UpdateAccessory.vue'),
              meta: {
                title: 'Cập nhật bộ sản phẩm tặng kèm - DDV'
              }
            }
          ]
        },
        {
          path: '/promotion-accessories',
          component: () =>
            import('@/view/pages/promotion-accessories/PromotionAcc.vue'),
          children: [
            {
              path: '/',
              name: 'list-promotion-accessories',
              component: () =>
                import(
                  '@/view/pages/promotion-accessories/ListPromotionAcc.vue'
                ),
              meta: {
                title: 'Danh sách bộ sản phẩm khuyến mãi - DDV'
              }
            },
            {
              path: 'add-promotion-accessories',
              name: 'add-promotion-accessories',
              component: () =>
                import(
                  '@/view/pages/promotion-accessories/AddPromotionAcc.vue'
                ),
              meta: {
                title: 'Thêm mới bộ sản phẩm khuyến mãi - DDV'
              }
            },
            {
              path: 'update-promotion-accessories',
              name: 'update-promotion-accessories',
              component: () =>
                import(
                  '@/view/pages/promotion-accessories/UpdatePromotionAcc.vue'
                ),
              meta: {
                title: 'Cập nhật bộ sản phẩm khuyến mãi - DDV'
              }
            }
          ]
        },
        {
          path: '/warranty-packages',
          component: () =>
            import('@/view/pages/warranty-package/WarrantyPackage.vue'),
          children: [
            {
              path: '/',
              name: 'list-warranty-packages',
              component: () => import('@/view/pages/warranty-package/List.vue'),
              meta: {
                title: 'Danh sách gói bảo hành - DDV'
              }
            },
            {
              path: 'add-warranty-package',
              name: 'add-warranty-package',
              component: () => import('@/view/pages/warranty-package/Add.vue'),
              meta: {
                title: 'Thêm mói gói bảo hành - DDV'
              }
            },
            {
              path: 'update-warranty-package',
              name: 'update-warranty-package',
              component: () =>
                import('@/view/pages/warranty-package/Update.vue'),
              meta: {
                title: 'Cập nhật gói bảo hành - DDV'
              }
            }
          ]
        },
        {
          path: '/wholesaleInvoices',
          component: () => import('@/view/pages/bills/WholesaleInvoice.vue'),
          children: [
            {
              path: '/',
              name: 'list-wholesale-invoice',
              component: () =>
                import('@/view/pages/bills/ListWholesaleInvoice.vue'),
              meta: {
                title: 'Danh sách hóa đơn bán buôn - DDV'
              }
            },
            {
              path: 'add-wholesale-invoice',
              name: 'add-wholesale-invoice',
              component: () =>
                import('@/view/pages/bills/AddWholesaleInvoice.vue'),
              meta: {
                title: 'Thêm mới hóa đơn bán buôn - DDV'
              }
            },
            {
              path: 'update-wholesale-invoice',
              name: 'update-wholesale-invoice',
              component: () =>
                import('@/view/pages/bills/UpdateWholesaleInvoice.vue'),
              meta: {
                title: 'Cập nhật hóa đơn bán buôn - DDV'
              }
            }
          ]
        },
        {
          path: '/returnBills',
          component: () => import('@/view/pages/bills/ReturnBill.vue'),
          children: [
            {
              path: '/',
              name: 'list-return-bill',
              component: () => import('@/view/pages/bills/ListReturnBill.vue'),
              meta: {
                title: 'Danh sách hóa đơn trả hàng - DDV'
              }
            },
            {
              path: 'add-return-bill',
              name: 'add-return-bill',
              component: () => import('@/view/pages/bills/AddReturnBill.vue'),
              meta: {
                title: 'Thêm mới hóa đơn trả hàng - DDV'
              }
            },
            {
              path: 'update-return-bill',
              name: 'update-return-bill',
              component: () =>
                import('@/view/pages/bills/UpdateReturnBill.vue'),
              meta: {
                title: 'Cập nhật hóa đơn trả hàng - DDV'
              }
            },
            {
              path: 'add-return-bill-with-no-bill',
              name: 'add-return-bill-with-no-bill',
              component: () =>
                import('@/view/pages/bills/AddReturnBillWithNoBill.vue')
            }
          ]
        },
        {
          path: '/settings',
          component: () => import('@/view/pages/settings/Setting.vue'),
          children: [
            {
              path: '/',
              name: 'list-setting',
              component: () => import('@/view/pages/settings/ListSetting.vue'),
              meta: {
                title: 'Cài đặt - DDV'
              }
            }
          ]
        },
        {
          path: '/imei-histories',
          component: () => import('@/view/pages/imeis/ImeiHistory.vue'),
          children: [
            {
              path: '/',
              name: 'list-imei-history',
              component: () => import('@/view/pages/imeis/ListImeiHistory.vue'),
              meta: {
                title: 'Lịch sử IMEI - DDV'
              }
            }
          ]
        },
        {
          path: '/external-categories',
          component: () =>
            import('@/view/pages/categories/ExternalCategories.vue'),
          children: [
            {
              path: '/',
              name: 'list-external-category',
              component: () =>
                import('@/view/pages/categories/ListExternalCate.vue'),
              meta: {
                title: 'Danh mục sản phẩm - DDV'
              }
            }
          ]
        },
        {
          path: '/profile',
          component: () => import('@/view/pages/profile/Profile.vue'),
          children: [
            {
              path: 'change-password',
              name: 'change-password',
              component: () => import('@/view/pages/profile/ChangePassword.vue')
            },
            {
              path: 'change-store',
              name: 'change-store',
              component: () => import('@/view/pages/profile/ChangeStore.vue')
            }
          ]
        },
        {
          path: '/productStock',
          component: () => import('@/view/pages/stocks/ProductStock.vue'),
          children: [
            {
              path: '',
              name: 'list-product-stock',
              component: () =>
                import('@/view/pages/stocks/ListProductStock.vue'),
              meta: {
                title: 'Danh sách tồn kho - DDV'
              }
            }
          ]
        },
        {
          path: '/reports',
          component: () => import('@/view/pages/reports/Reports.vue'),
          children: [
            {
              path: 'accounting/cash',
              name: 'report-accounting-cash',
              component: () =>
                import('@/view/pages/reports/accounting/ListByStore.vue'),
              meta: {
                title: 'Báo cáo thu chi theo cửa hàng - DDV'
              }
            },
            {
              path: 'accounting/money',
              name: 'report-accounting-money',
              component: () =>
                import(
                  '@/view/pages/reports/accounting/SaleSummariesByStore.vue'
                ),
              meta: {
                title: 'Báo cáo tổng tiền theo cửa hàng - DDV'
              }
            }
          ]
        },
        {
          path: '/order-cant-serve',
          component: () =>
            import('@/view/pages/order-cant-serve/OrderCantServe.vue'),
          children: [
            {
              path: '',
              name: 'order-cant-serve',
              component: () =>
                import('@/view/pages/order-cant-serve/ListOrderCantServe.vue'),
              meta: {
                title: 'Danh sách phiếu không phục vụ được'
              }
            }
          ]
        },
        {
          path: '/customer-serve',
          component: () =>
            import('@/view/pages/customer-serve/CustomerServe.vue'),
          children: [
            {
              path: '',
              name: 'customer-serve',
              component: () =>
                import('@/view/pages/customer-serve/ListCustomerServe.vue'),
              meta: {
                title: 'Thống kê lượt phục vụ'
              }
            }
          ]
        },
        {
          path: '/points',
          component: () => import('@/view/pages/promotions-point/Point.vue'),
          children: [
            {
              path: '/',
              name: 'point-setting',
              component: () =>
                import('@/view/pages/promotions-point/PointSetting.vue'),
              meta: {
                title: 'Điểm tích lũy - DDV'
              }
            },
            {
              path: 'add-point-program',
              name: 'add-point-program',
              component: () =>
                import('@/view/pages/promotions-point/AddPointProgram.vue'),
              meta: {
                title: 'Chương trình điểm tích lũy - DDV'
              }
            },
            {
              path: 'update-point-program',
              name: 'update-point-program',
              component: () =>
                import('@/view/pages/promotions-point/UpdatePointProgram.vue'),
              meta: {
                title: 'Cập nhật chương trình điểm tích lũy - DDV'
              }
            }
          ]
        },
        {
          path: '/customer-care-form',
          component: () =>
            import('@/view/pages/customer-care/CustomerCareForm.vue'),
          children: [
            {
              path: '/',
              name: 'list-customer-care-form',
              component: () =>
                import('@/view/pages/customer-care/ListCustomerCareForm.vue'),
              meta: {
                title: 'Hình thức chăm sóc khách hàng - DDV'
              }
            }
          ]
        },
        {
          path: '/customer-care',
          component: () =>
            import('@/view/pages/customer-care/CustomerCare.vue'),
          children: [
            {
              path: '/',
              name: 'list-customer-care',
              component: () =>
                import('@/view/pages/customer-care/ListCustomerCare.vue'),
              meta: {
                title: 'Chăm sóc khách hàng - DDV'
              }
            },
            {
              path: 'add-customer-care',
              name: 'add-customer-care',
              component: () =>
                import('@/view/pages/customer-care/AddCustomerCare.vue'),
              meta: {
                title: 'Thêm mới phiếu chăm sóc khách hàng - DDV'
              }
            },
            {
              path: 'update-customer-care',
              name: 'update-customer-care',
              component: () =>
                import('@/view/pages/customer-care/UpdateCustomerCare.vue'),
              meta: {
                title: 'Cập nhật phiếu chăm sóc khách hàng - DDV'
              }
            }
          ]
        },
        {
          path: '/discount-programs',
          component: () =>
            import('@/view/pages/discount-program/DiscountProgram.vue'),
          children: [
            {
              path: '/',
              name: 'list-discount-program',
              component: () => import('@/view/pages/discount-program/List.vue'),
              meta: {
                title: 'Danh sách chương trình chiết khấu - DDV'
              }
            },
            {
              path: 'add',
              name: 'add-discount-program',
              component: () => import('@/view/pages/discount-program/Add.vue'),
              meta: {
                title: 'Thêm mới chương trình chiết khấu - DDV'
              }
            },
            {
              path: 'update/:discountProgramId',
              name: 'update-discount-program',
              component: () =>
                import('@/view/pages/discount-program/Update.vue'),
              meta: {
                title: 'Cập nhật chương trình chiết khấu - DDV'
              }
            }
          ]
        },
        {
          path: '/tradein-programs',
          component: () =>
            import('@/view/pages/tradein-program/TradeInProgram.vue'),
          children: [
            {
              path: '/',
              name: 'list-tradein-program',
              component: () => import('@/view/pages/tradein-program/List.vue'),
              meta: {
                title: 'Danh sách chương trình thu cũ - DDV'
              }
            },
            {
              path: 'add',
              name: 'add-tradein-program',
              component: () => import('@/view/pages/tradein-program/Add.vue'),
              meta: {
                title: 'Thêm mới chương trình thu cũ - DDV'
              }
            },
            {
              path: 'update',
              name: 'update-tradein-program',
              component: () =>
                import('@/view/pages/tradein-program/Update.vue'),
              meta: {
                title: 'Cập nhật chương trình thu cũ - DDV'
              }
            }
          ]
        },
        {
          path: '/trade-in',
          component: () => import('@/view/pages/trade-in/TradeIn.vue'),
          children: [
            {
              path: '/',
              name: 'list-trade-in',
              component: () => import('@/view/pages/trade-in/List.vue'),
              meta: {
                title: 'Danh sách phiếu định giá - DDV'
              }
            },
            {
              path: 'update',
              name: 'update-trade-in',
              component: () => import('@/view/pages/trade-in/Update.vue'),
              meta: {
                title: 'Thêm mới phiếu định giá - DDV'
              }
            },
            {
              path: 'import-trade-in',
              name: 'import-trade-in',
              component: () =>
                import('@/view/pages/trade-in/ImportTradeIn.vue'),
              meta: {
                title: 'Tạo phiếu thu cũ - DDV'
              }
            }
          ]
        },
        {
          path: '/import-trade-in',
          component: () => import('@/view/pages/trade-in/ITradeIn.vue'),
          children: [
            {
              path: '/',
              name: 'list-import-trade-in',
              component: () => import('@/view/pages/trade-in/ListITradeIn.vue'),
              meta: {
                title: 'Danh sách phiếu thu cũ - DDV'
              }
            },
            {
              path: 'update-import-trade-in',
              name: 'update-import-trade-in',
              component: () =>
                import('@/view/pages/trade-in/UpdateImportTradeIn.vue'),
              meta: {
                title: 'Cập nhật phiếu thu cũ - DDV'
              }
            }
          ]
        },
        {
          path: '/bill-deleted',
          component: () => import('@/view/pages/bills/BillDeleted.vue'),
          children: [
            {
              path: '/',
              name: 'list-bill-deleted',
              component: () => import('@/view/pages/bills/ListBillDeleted.vue'),
              meta: {
                title: 'Danh sách hóa đơn đã xóa - DDV'
              }
            },
            {
              path: 'retail-bill-deleted',
              name: 'retail-bill-deleted',
              component: () =>
                import('@/view/pages/bills/RetailBillDeleted.vue'),
              meta: {
                title: 'Chi tiết hóa đơn đã xóa - DDV'
              }
            },
            {
              path: 'wholesale-bill-deleted',
              name: 'wholesale-bill-deleted',
              component: () =>
                import('@/view/pages/bills/WholesaleBillDeleted.vue'),
              meta: {
                title: 'Chi tiết hóa đơn đẵ xòa - DDV'
              }
            }
          ]
        },
        {
          path: '/companies',
          component: () => import('@/view/pages/companies/Company.vue'),
          children: [
            {
              path: '/',
              name: 'list-company',
              component: () => import('@/view/pages/companies/List.vue'),
              meta: {
                title: 'Danh sách doanh nghiệp - DDV'
              }
            }
          ]
        },
        {
          path: '/reward',
          component: () => import('@/view/pages/reward/Reward.vue'),
          children: [
            {
              path: 'reward-category',
              name: 'reward-category',
              component: () =>
                import('@/view/pages/reward/ListRewardCategory.vue'),
              meta: {
                title: 'Danh sách nhóm sản phẩm hoa hồng - DDV'
              }
            },
            {
              path: 'reward-program',
              name: 'reward-program',
              component: () =>
                import('@/view/pages/reward/ListRewardProgram.vue'),
              meta: {
                title: 'Danh sách chương trình thưởng thưởng hoa hồng NCH - DDV'
              }
            },
            {
              path: 'reward-accessory-program',
              name: 'reward-accessory-program',
              component: () =>
                import('@/view/pages/reward/ListRewardAccessoryProgram.vue'),
              meta: {
                title: 'Danh sách chương trình thưởng hoa hồng NHPK - DDV'
              }
            },
            {
              path: 'reward-month-phone-program',
              name: 'reward-month-phone-program',
              component: () =>
                import('@/view/pages/reward/ListRewardMonthPhoneProgram.vue'),
              meta: {
                title:
                  'Danh sách chương trình thưởng hoa hồng NĐT - DDV (Theo tháng)'
              }
            },
            {
              path: 'assessory-industry-expense',
              name: 'assessory-industry-expense',
              component: () =>
                import('@/view/pages/reward/ListAssessoryIndustryExpense.vue'),
              meta: {
                title: 'Danh sách nhóm chi phí - DDV'
              }
            },
            {
              path: 'add-reward-category',
              name: 'add-reward-category',
              component: () =>
                import('@/view/pages/reward/AddRewardCategory.vue'),
              meta: {
                title: 'Thêm mới nhóm sản phẩm hoa hồng - DDV'
              }
            },
            {
              path: 'update-reward-category',
              name: 'update-reward-category',
              component: () =>
                import('@/view/pages/reward/UpdateRewardCategory.vue'),
              meta: {
                title: 'Cập nhật nhóm sản phẩm hoa hồng - DDV'
              }
            },
            {
              path: 'add-reward-program',
              name: 'add-reward-program',
              component: () =>
                import('@/view/pages/reward/AddRewardProgram.vue'),
              meta: {
                title: 'Thêm mới chương trình thưởng hoa hồng - DDV'
              }
            },
            {
              path: 'update-reward-program',
              name: 'update-reward-program',
              component: () =>
                import('@/view/pages/reward/UpdateRewardProgram.vue'),
              meta: {
                title: 'Cập nhật chương trình thưởng hoa hồng - DDV'
              }
            },
            {
              path: 'add-reward-accessory-program',
              name: 'add-reward-accessory-program',
              component: () =>
                import('@/view/pages/reward/AddRewardAccessoryProgram.vue'),
              meta: {
                title: 'Thêm mới chương trình thưởng hoa hồng - DDV'
              }
            },
            {
              path: 'update-reward-accessory-program',
              name: 'update-reward-accessory-program',
              component: () =>
                import('@/view/pages/reward/UpdateRewardAccessoryProgram.vue'),
              meta: {
                title: 'Cập nhật chương trình thưởng hoa hồng - DDV'
              }
            },
            {
              path: 'add-reward-month-phone-program',
              name: 'add-reward-month-phone-program',
              component: () =>
                import('@/view/pages/reward/AddRewardMonthPhoneProgram.vue'),
              meta: {
                title: 'Thêm mới chương trình thưởng hoa hồng - DDV'
              }
            },
            {
              path: 'update-reward-month-phone-program',
              name: 'update-reward-month-phone-program',
              component: () =>
                import('@/view/pages/reward/UpdateRewardMonthPhoneProgram.vue'),
              meta: {
                title: 'Cập nhật chương trình thưởng hoa hồng - DDV'
              }
            },
            {
              path: 'add-assessory-industry-expense',
              name: 'add-assessory-industry-expense',
              component: () =>
                import('@/view/pages/reward/AddAssessoryIndustryExpense.vue'),
              meta: {
                title: 'Thêm mới chương trình thưởng hoa hồng NHPK - DDV'
              }
            },
            {
              path: 'update-assessory-industry-expense',
              name: 'update-assessory-industry-expense',
              component: () =>
                import(
                  '@/view/pages/reward/UpdateAssessoryIndustryExpense.vue'
                ),
              meta: {
                title: 'Cập nhật chương trình thưởng hoa hồng NHPK - DDV'
              }
            },
            {
              path: 'reward-detail',
              name: 'reward-detail',
              component: () =>
                import('@/view/pages/reward/ListRewardDetail.vue'),
              meta: {
                title: 'Cộng trừ khác hoa hồng cửa hàng - DDV'
              }
            },
            {
              path: 'reward-accessory-detail',
              name: 'reward-accessory-detail',
              component: () =>
                import('@/view/pages/reward/ListRewardAccessoryDetail.vue'),
              meta: {
                title: 'Cộng trừ khác hoa hồng Ngành hàng phụ kiện - DDV'
              }
            }
          ]
        },
        {
          path: '/area',
          component: () => import('@/view/pages/area/Area.vue'),
          children: [
            {
              path: '/',
              name: 'list-area',
              component: () => import('@/view/pages/area/List.vue'),
              meta: {
                title: 'Danh sách khu vực - DDV'
              }
            }
          ]
        },
        {
          path: '/region',
          component: () => import('@/view/pages/region/Region.vue'),
          children: [
            {
              path: '/',
              name: 'list-region',
              component: () => import('@/view/pages/region/List.vue'),
              meta: {
                title: 'Danh sách vùng - DDV'
              }
            }
          ]
        },
        {
          path: '/reward-reports',
          component: () =>
            import('@/view/pages/reward-report/RewardReport.vue'),
          children: [
            {
              path: 'overview',
              name: 'overview',
              component: () =>
                import('@/view/pages/reward-report/Overview.vue'),
              meta: {
                title: 'Báo cáo hoa hồng tổng hợp theo cửa hàng - DDV'
              }
            },
            {
              path: 'detail',
              name: 'detail',
              component: () => import('@/view/pages/reward-report/Detail.vue'),
              meta: {
                title: 'Báo cáo hoa hồng chi tiết theo cửa hàng - DDV'
              }
            },
            {
              path: 'list-bill-by-store',
              name: 'list-bill-by-store',
              component: () =>
                import('@/view/pages/reward-report/ListBillByStore.vue'),
              meta: {
                title: 'Danh sách hóa đơn theo cửa hàng - DDV'
              }
            },
            {
              path: 'detail-by-employee',
              name: 'detail-by-employee',
              component: () =>
                import('@/view/pages/reward-report/DetailByEmployee.vue'),
              meta: {
                title: 'Báo cáo hoa hồng chi tiết theo nhân viên - DDV'
              }
            },
            {
              path: 'list-bill-by-employee',
              name: 'list-bill-by-employee',
              component: () =>
                import('@/view/pages/reward-report/ListBillByEmployee.vue'),
              meta: {
                title: 'Danh sách hóa đơn theo nhân viên - DDV'
              }
            }
          ]
        },
        {
          path: '/warranty-info',
          component: () =>
            import('@/view/pages/warranty-info/WarrantyInfo.vue'),
          children: [
            {
              path: '/',
              name: 'list-warranty-info',
              component: () => import('@/view/pages/warranty-info/List.vue'),
              meta: {
                title: 'Danh sách thông tin bảo hành - DDV'
              }
            }
          ]
        },
        {
          path: '/reward-accessory-reports',
          component: () =>
            import('@/view/pages/reward-report/RewardAccessoryReport.vue'),
          children: [
            {
              path: '/',
              name: 'reward-accessory-overview',
              component: () =>
                import(
                  '@/view/pages/reward-report/OverviewAccessoryReport.vue'
                ),
              meta: {
                title: 'Báo cáo hoa hồng NHPK - DDV'
              }
            }
          ]
        },
        {
          path: '/reward-phone-reports',
          component: () =>
            import('@/view/pages/reward-phone-report/RewardPhoneReport.vue'),
          children: [
            {
              path: 'month-reports',
              name: 'month-reports',
              component: () =>
                import(
                  '@/view/pages/reward-phone-report/OverviewMonthReport.vue'
                ),
              meta: {
                title: 'Báo cáo hoa hồng tổng hợp - DDV'
              }
            }
          ]
        },
        {
          path: '/product-component-categories',
          component: () =>
            import(
              '@/view/pages/product-component-categories/ProductComponentCategory.vue'
            ),
          children: [
            {
              path: '/',
              name: 'list-product-component-category',
              component: () =>
                import('@/view/pages/product-component-categories/List.vue'),
              meta: {
                title: 'Danh mục linh kiện - DDV'
              }
            }
          ]
        },
        {
          path: '/product-component',
          component: () =>
            import('@/view/pages/product-component/ProductComponent.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-component',
              component: () =>
                import('@/view/pages/product-component/List.vue'),
              meta: {
                title: 'Danh sách linh kiện - DDV'
              }
            },
            {
              path: 'import-product-component',
              name: 'import-product-component',
              component: () =>
                import('@/view/pages/product-component/Import.vue'),
              meta: {
                title: 'Nhập sản phẩm linh kiện - DDV'
              }
            }
          ]
        },
        {
          path: '/product-component-group',
          component: () =>
            import(
              '@/view/pages/product-component-group/ProductComponentGroup.vue'
            ),
          children: [
            {
              path: '/',
              name: 'list-product-component-group',
              component: () =>
                import('@/view/pages/product-component-group/List.vue'),
              meta: {
                title: 'Danh sách nhóm sản phẩm linh kiện - DDV'
              }
            },
            {
              path: 'add-product-component-group',
              name: 'add-product-component-group',
              component: () =>
                import('@/view/pages/product-component-group/Add.vue'),
              meta: {
                title: 'Thêm mới nhóm sản phẩm linh kiện - DDV'
              }
            },
            {
              path: 'update-product-component-group',
              name: 'update-product-component-group',
              component: () =>
                import('@/view/pages/product-component-group/Update.vue'),
              meta: {
                title: 'Cập nhật nhóm sản phẩm linh kiện - DDV'
              }
            }
          ]
        },
        {
          path: '/transfer-stock-to-nhanh',
          component: () => import('@/view/pages/stocks/TransferToNhanh.vue'),
          children: [
            {
              path: '/',
              name: 'list-transfer-stock-to-nhanh',
              component: () =>
                import('@/view/pages/stocks/ListTStockToNhanh.vue'),
              meta: {
                title: 'Danh sách phiếu chuyển kho sang hệ thống cũ - DDV'
              }
            },
            {
              path: 'add-transfer-stock-to-nhanh',
              name: 'add-transfer-stock-to-nhanh',
              component: () =>
                import('@/view/pages/stocks/AddTStockToNhanh.vue'),
              meta: {
                title: 'Thêm mới phiếu chuyển kho sang hệ thống cũ - DDV'
              }
            },
            {
              path: 'update-transfer-stock-to-nhanh',
              name: 'update-transfer-stock-to-nhanh',
              component: () =>
                import('@/view/pages/stocks/UpdateTStockToNhanh.vue'),
              meta: {
                title: 'Cập nhật phiếu chuyển kho sang hệ thống cũ - DDV'
              }
            }
          ]
        },
        {
          path: '/require-stock-to-nhanh',
          component: () => import('@/view/pages/stocks/RStockToNhanh.vue'),
          children: [
            {
              path: '/',
              name: 'list-require-stock-to-nhanh',
              component: () =>
                import('@/view/pages/stocks/ListRStockToNhanh.vue'),
              meta: {
                title:
                  'Danh sách phiếu yêu cầu chuyển kho sang hệ thống cũ - DDV'
              }
            },
            {
              path: 'approve-transfer-stock-to-nhanh',
              name: 'approve-transfer-stock-to-nhanh',
              component: () =>
                import('@/view/pages/stocks/ApproveTransferToNhanh.vue'),
              meta: {
                title: 'Duyệt phiếu yêu cầu chuyển kho sang hệ thống cũ - DDV'
              }
            }
          ]
        },
        {
          path: '/coupon',
          component: () => import('@/view/pages/coupon/Coupon.vue'),
          children: [
            {
              path: '/',
              name: 'list-coupon',
              component: () => import('@/view/pages/coupon/List.vue'),
              meta: {
                title: 'Danh sách chương trình coupon - DDV'
              }
            },
            {
              path: 'add-coupon',
              name: 'add-coupon',
              component: () => import('@/view/pages/coupon/Add.vue'),
              meta: {
                title: 'Thêm mới chương trình coupon - DDV'
              }
            },
            {
              path: 'update-coupon',
              name: 'update-coupon',
              component: () => import('@/view/pages/coupon/Update.vue'),
              meta: {
                title: 'Cập nhật chương trình coupon - DDV'
              }
            }
          ]
        },
        {
          path: '/revenue-report',
          component: () =>
            import('@/view/pages/revenue-report/RevenueReport.vue'),
          children: [
            {
              path: 'by-product',
              name: 'by-product',
              component: () =>
                import('@/view/pages/revenue-report/ByProduct.vue'),
              meta: {
                title: 'Báo cáo doanh thu theo sản phẩm - DDV'
              }
            }
          ]
        },
        {
          path: '/transaction-categories',
          component: () =>
            import('@/view/pages/transaction-category/TransactionCategory.vue'),
          children: [
            {
              path: '/',
              name: 'list-transaction-categories',
              component: () =>
                import('@/view/pages/transaction-category/List.vue'),
              meta: {
                title: 'Danh sách danh mục thu chi - DDV'
              }
            }
          ]
        },
        {
          path: '/product-stockslip',
          component: () => import('@/view/pages/stocks/ProductStockSlip.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-stockslip',
              component: () =>
                import('@/view/pages/stocks/ListProductStockSlip.vue'),
              meta: {
                title: 'Danh sách sản phẩm XNK - DDV'
              }
            }
          ]
        },
        {
          path: '/product-stockslip-reports',
          component: () =>
            import('@/view/pages/product-stockslip/ProductStockSlip.vue'),
          children: [
            {
              path: '/',
              name: 'list-product-stockslip-reports',
              component: () =>
                import('@/view/pages/product-stockslip/List.vue'),
              meta: {
                title: 'Báo cáo sản phẩm XNK - DDV'
              }
            }
          ]
        },
        {
          path: '/product-sale-reports',
          component: () =>
            import('@/view/pages/product-stockslip/ProductSale.vue'),
          children: [
            {
              path: '',
              name: 'list-product-sale-reports',
              component: () =>
                import('@/view/pages/product-stockslip/ListProductSale.vue'),
              meta: {
                title: 'Báo cáo sản phẩm bán chạy - DDV'
              }
            }
          ]
        },
        {
          path: '/product-imei-sale-reports',
          component: () =>
            import('@/view/pages/product-imei-sale/ProductIMEISale.vue'),
          children: [
            {
              path: '',
              name: 'list-product-imei-sale-reports',
              component: () =>
                import('@/view/pages/product-imei-sale/List.vue'),
              meta: {
                title: 'Tổng hợp SP IMEI đã bán - DDV'
              }
            }
          ]
        },
        {
          path: '/inventory-balance',
          component: () =>
            import('@/view/pages/inventory-balance/InventoryBalance.vue'),
          children: [
            {
              path: '/',
              name: 'list-inventory-balance',
              component: () =>
                import('@/view/pages/inventory-balance/List.vue'),
              meta: {
                title: 'Danh sách phiếu cân kho - DDV'
              }
            },
            {
              path: 'add-inventory-balance',
              name: 'add-inventory-balance',
              component: () => import('@/view/pages/inventory-balance/Add.vue'),
              meta: {
                title: 'Thêm mới phiếu cân kho - DDV'
              }
            }
          ]
        },
        {
          path: '/imei-balance',
          component: () =>
            import('@/view/pages/inventory-balance/IMEIBalance.vue'),
          children: [
            {
              path: '/',
              name: 'imei-balance',
              component: () =>
                import('@/view/pages/inventory-balance/ImportIMEIBalance.vue'),
              meta: {
                title: 'Nhập file cân kho IMEI - DDV'
              }
            }
          ]
        },
        {
          path: '/tools',
          component: () => import('@/view/pages/tools/Tool.vue'),
          children: [
            {
              path: 'list',
              name: 'list-cron-job',
              component: () => import('@/view/pages/tools/List.vue'),
              meta: {
                title: 'Cron job - DDV'
              }
            },
            {
              path: 'sync-product',
              name: 'sync-product',
              component: () => import('@/view/pages/tools/ProductSync.vue'),
              meta: {
                title: 'Đồng bộ sản phẩm - DDV'
              }
            },
            {
              path: 'sync-imei',
              name: 'sync-imei',
              component: () => import('@/view/pages/tools/Imei.vue')
            },
            {
              path: 'sync-bill',
              name: 'sync-bill',
              component: () => import('@/view/pages/tools/BillSync.vue'),
              meta: {
                title: 'Đồng bộ hóa đơn - DDV'
              }
            },
            {
              path: 'detail-sync/:code',
              name: 'detail-sync-edit',
              props: true,
              component: () => import('@/view/pages/tools/DetailSync.vue')
            },
            {
              path: 'detail-sync/',
              name: 'detail-sync-create',
              component: () => import('@/view/pages/tools/DetailSync.vue')
            }
          ]
        },
        {
          path: '/import-warranty-stocks',
          component: () =>
            import('@/view/pages/warranty-stock/ImportWarranty.vue'),
          children: [
            {
              path: '/',
              name: 'list-import-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/ListImport.vue'),
              meta: {
                title: 'Danh sách phiếu nhập kho bảo hành - DDV'
              }
            },
            {
              path: 'add-import-warranty',
              name: 'add-import-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/AddImport.vue'),
              meta: {
                title: 'Thêm mới phiếu nhập kho bảo hành - DDV'
              }
            },
            {
              path: 'update-import-warranty',
              name: 'update-import-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/UpdateImport.vue'),
              meta: {
                title: 'Cập nhật phiếu nhập kho bảo hành - DDV'
              }
            }
          ]
        },
        {
          path: '/export-warranty-stocks',
          component: () =>
            import('@/view/pages/warranty-stock/ExportWarranty.vue'),
          children: [
            {
              path: '/',
              name: 'list-export-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/ListExport.vue'),
              meta: {
                title: 'Danh sách phiếu xuất kho bảo hành - DDV'
              }
            },
            {
              path: 'add-export-warranty',
              name: 'add-export-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/AddExport.vue'),
              meta: {
                title: 'Thêm mới phiếu xuất kho bảo hành - DDV'
              }
            },
            {
              path: 'update-export-warranty',
              name: 'update-export-warranty',
              component: () =>
                import('@/view/pages/warranty-stock/UpdateExport.vue'),
              meta: {
                title: 'Cập nhật phiếu xuất kho bảo hành - DDV'
              }
            }
          ]
        },
        {
          path: '/storage-time',
          component: () => import('@/view/pages/storage-times/StorageTime.vue'),
          children: [
            {
              path: '/',
              name: 'list-storage-time',
              component: () => import('@/view/pages/storage-times/List.vue'),
              meta: {
                title: 'Danh sách thời gian lưu kho - DDV'
              }
            }
          ]
        },
        {
          path: '/virtual-stock',
          component: () =>
            import('@/view/pages/virtual-stock/VirtualStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-virtual-stock',
              component: () => import('@/view/pages/virtual-stock/List.vue'),
              meta: {
                title: 'Cấu hình cho phép tồn ảo sản phẩm- DDV'
              }
            },
            {
              path: 'info-virtual-stock',
              name: 'info-virtual-stock',
              component: () => import('@/view/pages/virtual-stock/Info.vue'),
              meta: {
                title: 'Cấu hình nhóm tồn ảo sản phẩm- DDV'
              }
            }
          ]
        },
        {
            path: '/product-price-config',
            component: () => import('@/view/pages/product-price/ProductPriceIndex.vue'),
            children: [
                {
                    path: '/',
                    name: 'list-product-price',
                    component: () => import('@/view/pages/product-price/ProductPrice.vue'),
                    meta: {
                        title: 'Danh sách cấu hình sản phẩm theo chi nhánh'
                    }
                },
                {
                    path: 'update/:id',
                    name: 'update-product-price',
                    component: () => import('@/view/pages/product-price/UpdateProductPrice.vue'),
                    meta: {
                        title: 'Cập nhật cấu hình sản phẩm theo chi nhánh'
                    }
                }
            ]
        },
        {
          path: '/reward-cr-reports',
          component: () =>
            import('@/view/pages/reward-cr-report/RewardCRReport.vue'),
          children: [
            {
              path: 'overview',
              name: 'overview',
              component: () =>
                import('@/view/pages/reward-cr-report/Overview.vue'),
              meta: {
                title: 'Báo cáo hoa hồng CSKH- DDV'
              }
            }
          ]
        },
        {
          path: '/purchase-orders',
          component: () =>
            import('@/view/pages/purchase-orders/PurchaseOrder.vue'),
          children: [
            {
              path: '/',
              name: 'list-purchase-orders',
              component: () => import('@/view/pages/purchase-orders/List.vue'),
              meta: {
                title: 'Phiếu yêu cầu nhập hàng- DDV'
              }
            },
            {
              path: 'info-purchase-orders',
              name: 'info-purchase-orders',
              component: () => import('@/view/pages/purchase-orders/Info.vue'),
              meta: {
                title: 'Thông tin phiếu yêu cầu nhập hàng- DDV'
              }
            },
            {
              path: 'approve-purchase-orders',
              name: 'approve-purchase-orders',
              component: () =>
                import('@/view/pages/purchase-orders/Approve.vue'),
              meta: {
                title: 'Duyệt phiếu yêu cầu nhập hàng- DDV'
              }
            },
            {
              path: 'print-purchase-orders',
              name: 'print-purchase-orders',
              component: () => import('@/view/pages/purchase-orders/Print.vue'),
              meta: {
                title: 'In phiếu yêu cầu nhập hàng- DDV'
              }
            }
          ]
        },
        {
          path: '/vat-invoice',
          component: () => import('@/view/pages/vat-invoice/VatInvoice.vue'),
          children: [
            {
              path: '/',
              name: 'vat-invoice',
              component: () =>
                import('@/view/pages/vat-invoice/VatInvoiceList.vue'),
              meta: {
                title: 'Hoá đơn đỏ - DDV'
              }
            }
          ]
        },
        {
          path: '/holding-stock',
          component: () =>
            import('@/view/pages/holding-stocks/HoldingStock.vue'),
          children: [
            {
              path: '/',
              name: 'list-holding-stock',
              component: () => import('@/view/pages/holding-stocks/List.vue'),
              meta: {
                title: 'Phiếu giữ hàng - DDV'
              }
            },
            {
              path: 'info-holding-stock',
              name: 'info-holding-stock',
              component: () => import('@/view/pages/holding-stocks/Info.vue'),
              meta: {
                title: 'Thông tin phiếu giữ hàng - DDV'
              }
            }
          ]
        },
        {
          path: '/mbf-transaction',
          component: () => import('@/view/pages/mbf/MBF.vue'),
          children: [
            {
              path: '/',
              name: 'mbf-transaction',
              component: () => import('@/view/pages/mbf/MBFTransactions.vue'),
              meta: {
                title: 'Hoá đơn mbf - DDV'
              }
            }
          ]
        },
        {
          path: '/mbf-product',
          component: () => import('@/view/pages/mbf/MBF.vue'),
          children: [
            {
              path: '/',
              name: 'mbf-product',
              component: () => import('@/view/pages/mbf/MBFProducts.vue'),
              meta: {
                title: 'Quản lí sản phẩm mbf - DDV'
              }
            }
          ]
        },
        {
          path: '/reward-accessory',
          component: () =>
            import('@/view/pages/reward-accessories/Program.vue'),
          children: [
            {
              path: '/',
              name: 'list-reward-accessory',
              component: () =>
                import('@/view/pages/reward-accessories/List.vue'),
              meta: {
                title: 'Hoa hồng NHPK - DDV',
              },
            },
            {
              path: 'info-reward-accessory',
              name: 'info-reward-accessory',
              component: () =>
                import('@/view/pages/reward-accessories/Info.vue'),
              meta: {
                title: 'Thông tin hoa hồng NHPK - DDV',
              },
            },
          ],
        },
        {
          path: '/reward-accessory-report',
          component: () =>
            import('@/view/pages/reward-accessories/Program.vue'),
          children: [
            {
              path: '/',
              name: 'list-reward-accessory-report',
              component: () =>
                import('@/view/pages/reward-accessories/ListReport.vue'),
              meta: {
                title: 'Hoa hồng NHPK - DDV',
              },
            },
          ],
        },
        {
          path: '/reward-phone',
          component: () => import('@/view/pages/reward-phone/RewardPhone.vue'),
          children: [
            {
              path: '/',
              name: 'list-reward-phone',
              component: () =>
                import('@/view/pages/reward-phone/ListProgram.vue'),
              meta: {
                title: 'Hoa hồng NH máy - DDV',
              },
            },
            {
              path: 'info-reward-phone',
              name: 'info-reward-phone',
              component: () =>
                import('@/view/pages/reward-phone/ProgramInfo.vue'),
              meta: {
                title: 'Thông tin hoa hồng NH máy - DDV',
              },
            },
          ],
        },
        {
          path: '/reward-phone-report',
          component: () => import('@/view/pages/reward-phone/RewardPhone.vue'),
          children: [
            {
              path: '/',
              name: 'list-reward-phone-report',
              component: () =>
                import('@/view/pages/reward-phone/ListReport.vue'),
              meta: {
                title: 'Báo cáo hoa hồng NH máy - DDV',
              },
            },
          ],
        },
        {
          path: '/power-bi',
          component: () => import('@/view/pages/power-bi/PowerBI.vue'),
          children: [
            {
              path: '/',
              name: 'list-power-bi',
              component: () => import('@/view/pages/power-bi/PowerBIList.vue'),
              meta: {
                title: 'Cấu hình power bi - DDV'
              }
            }
          ]
        },
        {
          path: '/active-imei-report',
          component: () => import('@/view/pages/imei-active-reports/ImeiActiveReport.vue'),
          children: [
            {
              path: '/',
              name: 'list-active-imei-report',
              component: () => import('@/view/pages/imei-active-reports/List.vue'),
              meta: {
                title: 'Báo cáo kích hoạt IMEI - DDV'
              }
            }
          ]
        },
      ]
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/Auth'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/Login')
        }
      ]
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue')
    }
  ]
});

export default router;
