import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';
import menu from './menu.module';
import orderInfo from './order/order-info.module';
import orderCustomerInfo from './order/order-customer-info.module';
import orderDiscount from './order/order-discount.module';
import orderNote from './order/order-note.module';
import orderProducts from './order/order-product-table.module';
import orderSeletedStatus from './order/order-seleted-status.module';
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    menu,
    orderInfo,
    orderCustomerInfo,
    orderDiscount,
    orderNote,
    orderProducts,
    orderSeletedStatus
  }
});
