import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/jwt.service';
import router from '../../router';
import { LOGOUT } from '@/core/services/store/auth.module';
import store from './store';
import { get, uniq } from 'lodash';

import { makeToastFaile } from '../../utils/common';
/**
 * Service to call HTTP request via Axios
 */
let errorMessages = [];

//const READ_ONLY_BACKEND = 'http://localhost:3000/api/';
// const READ_ONLY_BACKEND = 'http://35.185.186.85/web-sale/v1/api/';
// const READ_ONLY_BACKEND = 'http://mb.viendidong.com/web-tester/v1/api/';
// const READ_ONLY_BACKEND = 'https://app.didongviet.vn/web-api/read/v1/api/';
const READ_ONLY_BACKEND = 'https://app.thegioimaycu.vn/web-api/v1/api/';
// const READ_ONLY_BACKEND = 'http://backup.viendidong.com/web-api/v1/api/';

//const READ_AND_WRITE_BACKEND = 'http://localhost:3000/api/';
// const READ_AND_WRITE_BACKEND = 'http://mb.viendidong.com/web-tester/v1/api/';
// const READ_AND_WRITE_BACKEND = 'https://app.didongviet.vn/web-api/write/v1/api/';
const READ_AND_WRITE_BACKEND = 'https://app.thegioimaycu.vn/web-api/v1/api/';
// const READ_AND_WRITE_BACKEND = 'http://backup.viendidong.com/web-api/v1/api/';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = READ_ONLY_BACKEND;
    Vue.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        switch (get(err, 'response.status')) {
          case 401:
            store.dispatch(LOGOUT);
            router.push({ name: 'login' });
            return Promise.reject(err);
          case 900:
            errorMessages.push(get(err, 'response.data.message'));
            setTimeout(() => {
              errorMessages = uniq(errorMessages);
              errorMessages.map((message) => {
                makeToastFaile(message);
              });
              errorMessages = [];
            }, 2000);
            return Promise.reject(err);
          default:
            return Promise.reject(err);
        }
      }
    );

    // Auto set token to request.
    Vue.axios.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${JwtService.getToken()}`;
      config.headers.version = '1.0.25';
      return config;
    });
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    return axios.get(`${resource}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      baseURL: READ_AND_WRITE_BACKEND,
    });
  },

  /**
   * Set the PATCH HTTP request
   * @param resource
   * @returns {*}
   */
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params, {
      baseURL: READ_AND_WRITE_BACKEND,
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params, {
      baseURL: READ_AND_WRITE_BACKEND,
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios
      .delete(resource, {
        baseURL: READ_AND_WRITE_BACKEND,
      })
      .catch((error) => {
        if (error.response) {
          makeToastFaile(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại'
          );
        } else {
          makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
        }
        return error;
      });
  },
};

export default ApiService;
