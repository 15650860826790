<template>
  <div id="app" class="d-flex flex-root">
    <nprogress-container></nprogress-container>
    <router-view></router-view>
  </div>
  <!-- set progressbar -->
</template>

<style lang="scss">
// 3rd party plugins css
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';

// Main demo style scss
@import 'assets/sass/style.vue';

// Check documentation for RTL css
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
import { DEFAULT_TITLE } from './utils/constants';

export default {
  name: 'MetronicVue',
  components: {
    NprogressContainer
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || DEFAULT_TITLE;
      }
    }
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  }
};
</script>
