import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './core/services/store';
import ApiService from './core/services/api.service';
// import MockService from "./core/mock/mock.service";
import { VERIFY_AUTH } from './core/services/store/auth.module';
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module';

import UUID from 'vue-uuid';
import VueAutosuggest from 'vue-autosuggest';
import VueMask from 'v-mask';
import NProgress from 'vue-nprogress';
import VueClipboard from 'vue-clipboard2';

Vue.config.productionTip = false;

Vue.use(UUID);
Vue.use(VueAutosuggest);
Vue.use(VueMask);
Vue.use(NProgress);

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;
const nprogress = new NProgress();
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard);

// Vue 3rd party plugins
import i18n from './core/plugins/vue-i18n';
import vuetify from './core/plugins/vuetify';
import './core/plugins/portal-vue';
import './core/plugins/bootstrap-vue';
import './core/plugins/perfect-scrollbar';
import './core/plugins/highlight-js';
import './core/plugins/inline-svg';
import './core/plugins/apexcharts';
import './core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';
// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.config.errorHandler = function (err) {
  console.log('errorHandler', err);
  nprogress.done();
};

Vue.directive('focus', {
  inserted: function (el) {
    // Focus the element
    el.focus();
  },
  update: function (el, binding) {
    var value = binding.value;
    if (value) {
      Vue.nextTick(function () {
        el.focus();
      });
    }
  }
});

new Vue({
  router,
  store,
  i18n,
  nprogress,
  vuetify,
  render: h => h(App)
}).$mount('#app');
